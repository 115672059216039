'use client';

import { PropsWithChildren, useEffect, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import CartIcon from '@/components/SVGIcons/CartIcon';

import { DiscountCodeInput } from './DiscountCodeInput';
import usePricesStore from '@/common/zustand/usePricesStore';
import { useMedia, useSubscriptionPreview } from '@/common/hooks';
import { usePageStore } from '@/common/zustand';
import TodayTotalOTP from './TodayTotalOTP';
import TodayTotalSub from './TodayTotalSub';
import { denominate, formatPrice, isSubscription } from './utils';
import ProductInfo from './ProductInfo';
import SubscriptionInfo from './SubscriptionInfo';
import { Offer } from '@/src/pages/[host]/[uid]/[slug]';
import { TARGET_MEDIA_WIDTH } from '@/src/components/Layout/Layout.constants';
import { ActiveTemplateEnum } from '@/src/components/Layout/Layout.enums';
import { usePaymentOption } from '@/src/hooks';
import { ArrowUpIcon } from '@/src/components/icons';
import CloseIcon from '../SVGIcons/CloseIcon';
import classNames from 'classnames';

interface IProps {
  customizeProduct?: Offer['customization']['product'];
  paymentPage: Omit<Offer, 'account'>;
  nameTemplate: string;
  accountSlug: string;
  mobileDrawer?: boolean;
}

const OrderSummary = ({
  paymentPage,
  accountSlug,
  mobileDrawer = false,
  children,
}: PropsWithChildren<IProps>): JSX.Element | null => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const subscriptionPreview = useSubscriptionPreview();

  const pricesLoading = usePricesStore((state) => state.loading);
  const setPrices = usePricesStore((state) => state.setPrices);
  const setPricesDiscount = usePricesStore((state) => state.setDiscount);
  const setPricesTax = usePricesStore((state) => state.setTax);
  const setSubDiscount = usePricesStore((state) => state.setSubDiscount);
  const setSubTax = usePricesStore((state) => state.setSubTax);
  const setTrialTax = usePricesStore((state) => state.setTrialTax);
  const setPricesLoading = usePricesStore((state) => state.setLoading);

  const getOtpPrices = usePageStore((state) => state.getOtpPrices);
  const taxCalcOTP = usePageStore((state) => state.taxCalcOTP);

  const setAddSubInvoiceItems = usePageStore(
    (state) => state.setAddSubInvoiceItems
  );

  const setSubscriptionAllItems = usePageStore(
    (state) => state.setSubscriptionAllItems
  );

  const isMobile = useMedia(TARGET_MEDIA_WIDTH);

  const setBumpSelectedState = usePageStore((state) => state.setBumpSelected);
  const activeTemplate = usePageStore((state) => state.activeTemplate);
  const bumpSelected = usePageStore((state) => state.bumpSelected);
  const paymentOption = usePaymentOption();

  const {
    t,
    i18n: { language },
  } = useTranslation(['paymentPage', 'prices']);

  if (!subscriptionPreview && isSubscription(paymentOption?.payment_type!)) {
    setSubDiscount(0);
    setSubTax(0);
    setPricesDiscount(0);
    setPricesTax(0);
    setAddSubInvoiceItems([]);
    setPricesLoading(false);
  } else if (subscriptionPreview) {
    let {
      mainPrice,
      trialPrice,
      discount,
      tax,
      mainTax,
      trialTax,
      mainDiscount,
      allItems,
    } = subscriptionPreview;

    setPrices('amount', {
      mainPrices: {
        subscription: {
          amount: mainPrice,
          trialAmount: trialPrice,
        },
      },
    });
    setSubDiscount(mainDiscount);
    setSubTax(mainTax);
    setTrialTax(trialTax);
    setPricesDiscount(discount);
    setPricesTax(tax);
    // We are storing prices in all items to be able to create subscription
    // if the price ID was originaly missing
    setSubscriptionAllItems(allItems);
    setPricesLoading(false);
  }

  const bump = usePricesStore((state) => state.bump);

  const {
    product,
    bumps: [bumpProduct],
    id: paymentPageId,
    main_product_label: mainProductLabel,
  } = paymentPage;

  const {
    title: mainProductTitle,
    id: productId,
    name: mainProductName,
  } = product;

  //Misc
  const currency = paymentPage.currency.symbol;
  const denominateCurr = denominate(currency);

  const MAIN_PRODUCT_TYPE = paymentOption?.payment_type;

  const customizeProduct = paymentPage?.customization?.product;
  const {
    requested_fields: { discount_enabled: discountEnabled },
  } = paymentPage.customization;

  const customization = bumpProduct.customization;
  const bumpPaymentOption = bumpProduct.payment_option;

  const bumpPrice = denominateCurr(bump.amount ?? 0);

  if (!isLoaded || !customizeProduct || !paymentOption) {
    return null;
  }

  const isOneTimeFee = MAIN_PRODUCT_TYPE === 'one_time_fee';

  const otpPrices = getOtpPrices();

  return (
    <div
      data-testid="order-summary"
      className={classNames('px-0 md:px-4 p-4', !mobileDrawer && 'bg-white')}
    >
      {mobileDrawer && (
        <div
          className={classNames(
            `flex border-accent-color-50 bg-accent-color-5 border-t-2 border-l-2 border-r-2 w-full p-3 lg:pr-1 mt-6 lg:mt-9 hover:cursor-pointer hover:opacity-80`,
            !drawerOpen && 'border-b-2'
          )}
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          <div className="text-sm align-middle flex-initial flex">
            <CartIcon />
            <span className="flex items-center justify-center ml-2 font-header">
              {drawerOpen ? t('hideOrderSummary') : t('showOrderSummary')}
              <div
                className={`transition-transform ${
                  drawerOpen ? '' : 'rotate-180 ml-2 pb-1'
                }`}
              >
                <ArrowUpIcon />
              </div>
            </span>
          </div>
          {MAIN_PRODUCT_TYPE === 'one_time_fee' ? (
            <TodayTotalOTP
              taxCalc={taxCalcOTP}
              prices={otpPrices}
              isMobileDrawer={true}
            />
          ) : (
            <TodayTotalSub
              option={paymentOption}
              currency={currency}
              bumpSelected={bumpSelected}
              isMobileDrawer={true}
            />
          )}
        </div>
      )}
      <div
        className={classNames('pb-4 xl:pb-2 pt-4 md:pt-0', {
          'border-accent-color-50 border-2': mobileDrawer,
          hidden: mobileDrawer && !drawerOpen,
          display: !mobileDrawer || drawerOpen,
          'pt-2': [ActiveTemplateEnum.MOBILE].includes(
            activeTemplate as ActiveTemplateEnum
          ),
          'border-accent-color': activeTemplate === ActiveTemplateEnum.MOBILE,
          'bg-white': drawerOpen,
        })}
      >
        {pricesLoading && (
          <div
            className="p-2 mb-4 text-md text-gray-800 rounded-lg bg-white w-5/12 m-auto inline"
            role="alert"
          >
            Recalculating prices ...
          </div>
        )}
        <div
          className={classNames('lg:px-0 bg-white border-b-2', {
            'pb-3': isOneTimeFee && isMobile && !mobileDrawer,
            'mx-3': mobileDrawer,
            'pb-6':
              !mobileDrawer &&
              (isMobile ||
                (!isSubscription(paymentOption.payment_type) && bumpSelected)),
            'pb-4':
              mobileDrawer &&
              (isMobile ||
                (!isSubscription(paymentOption.payment_type) && bumpSelected)),
          })}
        >
          <div>
            {bumpSelected || isMobile ? (
              <>
                <div className="flex">
                  <div className="flex-1 w-full max-w-[50%]">
                    {customizeProduct.logo && (
                      <div className={`relative w-full h-full`}>
                        <Image
                          alt="Product logo"
                          loader={() => customizeProduct.logo!}
                          src={customizeProduct.logo}
                          width={0}
                          height={0}
                          sizes="100vw"
                          unoptimized={true}
                          style={{ width: '100%', height: 'auto' }}
                          priority
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`mt-[-5px] flex-auto ${
                      customizeProduct.logo ? 'w-[50%] pl-4' : 'w-full'
                    }`}
                  >
                    <ProductInfo
                      product={
                        mainProductLabel || mainProductTitle || mainProductName
                      }
                      option={paymentOption}
                      language={language}
                      currency={currency}
                    />
                  </div>
                </div>
                {isSubscription(paymentOption.payment_type) && (
                  <SubscriptionInfo
                    option={paymentOption}
                    language={language}
                    currency={currency}
                  />
                )}
              </>
            ) : (
              <div>
                {customizeProduct.logo && (
                  <div className="relative w-100 h-100 pb-4">
                    <Image
                      alt="Product logo"
                      loader={() => customizeProduct.logo!}
                      src={customizeProduct.logo}
                      width={0}
                      height={0}
                      sizes="100vw"
                      unoptimized={true}
                      style={{ width: '100%', height: 'auto' }}
                      priority
                    />
                  </div>
                )}
                <div className={`flex-auto w-full`}>
                  <ProductInfo
                    product={
                      mainProductLabel || mainProductTitle || mainProductName
                    }
                    option={paymentOption}
                    language={language}
                    currency={currency}
                  />
                </div>
                {isSubscription(paymentOption.payment_type) && (
                  <SubscriptionInfo
                    option={paymentOption}
                    language={language}
                    currency={currency}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {bumpSelected && (
          <div
            className={classNames(
              'product-detail__wrapper lg:px-0',
              drawerOpen && 'px-3'
            )}
          >
            <div>
              <div className="flex border-b-2 py-6">
                <div className="flex-1 w-full max-w-[50%]">
                  {customization.img_link && (
                    <div className="relative w-full h-full">
                      <Image
                        alt="Product logo"
                        src={customization.img_link}
                        width={0}
                        unoptimized={true}
                        height={0}
                        sizes="100vw"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`flex-auto ${
                    customization.img_link ? 'w-[50%] pl-4' : 'w-full'
                  }`}
                >
                  <div className="font-semibold text-base sm:text-lg md:text-xl flex">
                    <span className="flex-1">
                      {customization.form_preview.product_name}
                    </span>
                    <span
                      className="text-right flex-initial self-center hover:cursor-pointer hover:opacity-90"
                      onClick={() => setBumpSelectedState(false)}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                  <label className={`mobile-bump__checkbox-added`}></label>
                  <p className="text-base sm:text-lg sm:mt-1 md:text-xl">
                    {bumpPaymentOption &&
                      `${currency}${formatPrice(bumpPrice)}`}
                  </p>
                  {bumpPaymentOption &&
                    isSubscription(bumpPaymentOption.payment_type) && (
                      <SubscriptionInfo
                        option={bumpPaymentOption}
                        language={language}
                        currency={currency}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!!children && <div className="pt-6">{children}</div>}
        <div
          className={classNames(
            'md:px-3 lg:px-0 leading-relaxed lg:text-xl text-gray-800',
            drawerOpen && 'px-3'
          )}
        >
          {isOneTimeFee ? (
            <TodayTotalOTP taxCalc={taxCalcOTP} prices={otpPrices} />
          ) : (
            <TodayTotalSub
              option={paymentOption}
              currency={currency}
              bumpSelected={bumpSelected}
            />
          )}
        </div>
        <DiscountCodeInput
          accountSlug={accountSlug}
          paymentPageId={paymentPageId}
          productId={productId}
          bumpId={paymentPage?.bumps?.[0]?.product?.id ?? null}
          discountEnabled={discountEnabled}
        />

        {accountSlug === 'cyberpanda-deadlinefunnel' && (
          <div className={`flex mt-3 ${drawerOpen ? 'px-4' : ''}`}>
            <span className="text-xs">{t('taxDescription')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
